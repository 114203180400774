<template>
  <v-container v-if="vueUsuarios">
    <v-row>
      <v-col cols="12">
        <PageToolbar :title="$tc('pages.usuario', 2)" icon="person_pin" dark />
      </v-col>
      <v-col cols="12">
        <ListaUsuarios />
      </v-col>
    </v-row>
    <v-btn
      :to="{ name: 'NovoUsuario' }"
      fixed
      right
      bottom
      fab
      dark
      color="buttons"
      :class="$vuetify.breakpoint.mdAndUp ? 'mr-12' : ''"
    >
      <v-icon>add</v-icon>
    </v-btn>
  </v-container>
  <NaoAutorizado v-else />
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Usuarios",
  components: {
    ListaUsuarios: () => import("./components/ListaUsuarios.vue"),
  },
  data: () => ({}),
  computed: {
    ...mapGetters("Componentes", ["getAccess"]),
    vueUsuarios() {
      return this.getAccess("vueUsuarios", "menu");
    },
  },
  mounted() {},
  created() {},
};
</script>

<style></style>
